import React from 'react';
import { useSelector } from 'react-redux';
import CreditDetails from 'ui/run/RunShow/components/CreditDetails';

const propTypes = {};
const defaultProps = {};

const RunCreditDetails = () => {
  const runDetails = useSelector((state) => state);
  const {
    runType, quantConfig, granularity
  } = runDetails;

  return (
    <div className="float-left mt-4">
      <CreditDetails
        runType={runType}
        granularity={granularity}
        isConsumed={false}
        instrumentGroups={_.get(quantConfig, 'instrumentGroups', [])}
        cases={_.get(quantConfig, 'cases', [])}
      />
    </div>
  );
};

RunCreditDetails.propTypes = propTypes;
RunCreditDetails.defaultProps = defaultProps;

export default RunCreditDetails;
