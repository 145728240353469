import moment from 'moment';
import React, { useState, useEffect, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { isRunEligibleForWeeklyExpiry } from 'common/utils/optionExpiryCycleHelper';
import { fetchOutputSchema } from 'common/quantConfig/outputSchema';
import { createRun, updateRun } from 'common/api/run';
import fetchValidationSchemas from 'common/api/validationSchema';
import { OPTIONS_EXPIRY } from 'modules/QuantBuilder/config';
import { getQuantConfigValidators, getMandatoryFieldsValidation } from 'common/utils/validators';
import { initiateUserAuth } from 'common/auth/modalLogin';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { ORDER_TYPES, RUN_TYPES, SEGMENT_CONFIG } from 'common/constants/index';
import qs from 'qs';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import { quotaVerificationDom } from 'ui/QuotaVerification/quotaDom';
import { getFolders } from 'common/api/folder';
import withErrorBoundary from 'common/components/withErrorBoundary/index';
import { AdvancedBuilder } from './components';
import {
  I18N_SCOPE, onUpdateQuantConfigBasedOnSegmentChange,
} from './config';
import {
  defaultOrderType, getQuantConfig, getQuantSupportSegment,
  isShowAutomaticModal, sanitizedEndDate,
  sanitizeQuantConfig, validateInstrumenWithStartDate
} from './helper';
import { defaultRunFormProps, runFormPropTypes } from './runFormPropTypes';
import RunFormHeader from './components/RunFormHeader';
import RunFormInstructions from './components/RunFormInstructions';
import RunFormConfirmationModal from './components/RunFormConfirmationModal';
import CreditDetails from '../RunShow/components/CreditDetails';
import RunFormHeadPiece from './components/RunFormHeadPiece';
import RunConfig from '../RunShow/components/RunConfig';

const propTypes = runFormPropTypes;
const defaultProps = defaultRunFormProps;

const Version1 = ({
  endDate: propsEndDate, folder, isClonedQuant,
  isHistoricRunDisabled, isLiveRunDisabled, maxEndDate,
  minStartDate: calendarStartDate, orderType: propsOrderType, prebuiltId, quantConfig: propsQuantConfig,
  runName, runType: propsRunType, startDate: propsStartDate, engineVersion: propsEngineVersion,
  granularity: propsGranularity, plan, currentUser, isEditQuant, strategyId
}) => {
  const { isUserSignedIn } = window;
  const planActiveTill = _.get(currentUser, 'plan_active_till', null);
  const isUserEnabled = _.get(currentUser, 'enabled', false);
  const broker = _.get(currentUser, 'broker', '');
  const methods = useForm();

  const [calendarEndDate] = useState(maxEndDate || moment.utc().subtract(1, 'days').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(sanitizedEndDate(propsEndDate, maxEndDate));
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(isUserSignedIn);
  const [name, setName] = useState(runName || '');
  const [orderType, setOrderType] = useState(defaultOrderType(propsOrderType, broker, planActiveTill));
  const [outputSchema, setOutputSchema] = useState([]);
  const modifedQuantConfig = useMemo(() => getQuantConfig(propsQuantConfig), [propsQuantConfig]);
  const [quantConfig, setQuantConfig] = useState(modifedQuantConfig);
  const [quantConfigValidators, setQuantConfigValidators] = useState({});
  const [runType, setRunType] = useState(propsRunType);
  const [granularity, setGranularity] = useState(propsGranularity);
  const [selectedFolder, setSelectedFolder] = useState(folder);
  const [shouldRenderErrors, setShouldRenderErrors] = useState(false);
  const [shouldShowEndDatePopOver, setShouldShowEndDatePopOver] = useState(false);
  const [startDate, setStartDate] = useState(moment.utc(propsStartDate).toDate());
  const [validationSchemas, setValidationSchemas] = useState({});
  const [validatorErrors, setValidatorErrors] = useState([]);
  const { monthly } = OPTIONS_EXPIRY;
  const [isLoading, setIsLoading] = useState(false);
  const [segment, setSegment] = useState(getQuantSupportSegment(plan,
    _.get(quantConfig, 'segment', SEGMENT_CONFIG.future)));
  const [isShowAutomaticConfirmationModal, setIsShowAutomaticConfirmationModal] = useState(false);
  const segments = _.get(plan, 'segments', []);
  const instrumentGroups = _.get(quantConfig, 'instrumentGroups', []);

  const { live } = RUN_TYPES;
  const isLive = runType === live;
  const { paper: { value: paper } } = ORDER_TYPES;
  const isActualOrder = orderType !== paper && isLive;
  const getBtnTitle = () => {
    if (runType === RUN_TYPES.historic) {
      if (isEditQuant) return 'update_historic_run';
      return 'create_historic_run';
    }
    if (isEditQuant) return 'update_live_run';
    return 'create_live_run';
  };

  const validateQuantConfig = (newQuantConfig) => {
    setValidatorErrors(getMandatoryFieldsValidation(newQuantConfig, validationSchemas));
  };

  const onUpdateSchema = (updatedQuantConfig) => {
    fetchOutputSchema(updatedQuantConfig).then((newOutputSchema) => {
      const alteredOutputSchema = _.map(newOutputSchema, (schema) => {
        const enumValue = _.get(schema, 'enum', []);
        if (_.isEmpty(enumValue)) return schema;

        return { ...schema, enum: _.reverse(enumValue) };
      });

      setOutputSchema(alteredOutputSchema);
    });
  };

  useEffect(() => {
    setQuantConfig(onUpdateQuantConfigBasedOnSegmentChange(quantConfig, segment, segments));
  }, [segment, quantConfig.maxInstrumentsInGroup]);

  const handleQuantConfigUpdate = (newQuantConfig) => {
    if (shouldRenderErrors) { validateQuantConfig(newQuantConfig); }
    if (newQuantConfig) onUpdateSchema(newQuantConfig);

    setQuantConfig(newQuantConfig);
  };

  const checkAndModifyOptionsExpiryCycle = () => {
    const currentOptionsExpiryCycle = _.get(quantConfig, 'optionsExpiryCycle', '');
    const orderConfigs = _.get(quantConfig, 'orderConfigs', []);

    const currentInstrumentGroup = _.isEmpty(instrumentGroups) ? [] : instrumentGroups;

    if (_.isEmpty(currentInstrumentGroup) || currentOptionsExpiryCycle === monthly) return;

    if (isRunEligibleForWeeklyExpiry(currentInstrumentGroup, startDate, orderConfigs)) return;

    handleQuantConfigUpdate({ ...quantConfig, optionsExpiryCycle: monthly }, null);
    reactToastify('Default options expiry change to Monthly', TOASTIFY_TYPES.ERROR);
  };

  useEffect(() => {
    fetchValidationSchemas().then((newValidationSchemas) => {
      setQuantConfigValidators(getQuantConfigValidators(newValidationSchemas));
      setValidationSchemas(newValidationSchemas);
    });
    onUpdateSchema(quantConfig);

    checkAndModifyOptionsExpiryCycle();
  }, []);

  useEffect(() => { checkAndModifyOptionsExpiryCycle(); }, [startDate, instrumentGroups, quantConfig]);

  useEffect(() => {
    const validationObj = validateInstrumenWithStartDate(instrumentGroups, startDate);
    const { isValid, instrument, validDate } = validationObj;
    if (!isValid) {
      setStartDate(validDate);
      reactToastify(`${instrument} available only from ${moment(validDate).format('YYYY-MM-DD')}`,
        TOASTIFY_TYPES.WARNING);
    }
  }, [instrumentGroups, startDate]);

  useEffect(() => {
    setTimeout(() => { setShouldShowEndDatePopOver(false); }, 5000);
  }, [shouldShowEndDatePopOver]);

  const executeEditRun = (runParams) => {
    const queryString = window.location.search;
    const parsedQueryString = qs.parse(queryString, { parseBooleans: true });
    const runId = _.get(parsedQueryString, 'edit_run_id');
    updateRun({ runId, run: { ...runParams } })
      .then(() => {
        window.location.href = `/runs/${runId}?locale=${I18n.locale}`;
      }).catch((error) => {
        try {
          error.json().then((e) => {
            const errors = _.get(e, 'errors', []);
            const errorMessages = _.join(_.get(_.values(errors), '0', []), ',');

            if (errorMessages) { reactToastify(errorMessages, TOASTIFY_TYPES.ERROR); }
          });
        } catch (exception) {
          reactToastify('Something went wrong, unable to edit run.', TOASTIFY_TYPES.ERROR);
        }
      });
    return null;
  };

  const executeCreateRun = (runParams) => {
    setIsLoading(true);
    createRun(runParams).catch((error) => {
      const errors = _.get(error.response, 'data.errors', []);
      const quantConfigErrors = _.get(errors, 'quant_config', []);
      const additionalErrors = _.filter(errors, (err, key) => key !== 'quant_config');
      const errorMessages = _.join(_.map(additionalErrors, '0'), ', ');
      setIsLoading(false);

      if (errorMessages) reactToastify(errorMessages, TOASTIFY_TYPES.ERROR);

      setValidatorErrors(quantConfigErrors);
      setShouldRenderErrors(true);
    });

    return null;
  };

  const executeRun = (runParams) => {
    if (isShowAutomaticModal(
      isActualOrder, orderType, instrumentGroups, isShowAutomaticConfirmationModal
    )) return setIsShowAutomaticConfirmationModal(true);

    setIsShowAutomaticConfirmationModal(false);
    if (isEditQuant) return executeEditRun(runParams);

    return quotaVerificationDom(instrumentGroups, runParams, executeCreateRun);
  };

  const handleCreateRun = () => {
    if (granularity === 'second') {
      const instruments = _.flattenDeep(instrumentGroups);
      const validInstruments = ['NFO:NIFTY', 'NFO:BANKNIFTY', 'NFO:FINNIFTY'];
      const baseErrorMessage = 'Historic Data Type: "Second",';
      const erorrToastOptions = {
        position: 'top-right',
        autoClose: 6 * 1000,
        className: 'large-toast'
      };
      if (_.chain(instruments).difference(validInstruments).value().length > 0) {
        reactToastify(
          `${baseErrorMessage} supported only for ${validInstruments.join(' | ')}`,
          TOASTIFY_TYPES.ERROR,
          erorrToastOptions
        );
        return null;
      }
      if (moment(startDate) < moment('2023-01-01', 'YYYY-MM-DD')) {
        reactToastify(
          `${baseErrorMessage} supported only from "2023 Jan 1st"`,
          TOASTIFY_TYPES.ERROR,
          erorrToastOptions,
        );
        return null;
      }
    }
    const folderId = _.get(selectedFolder, 'id', '');
    const sanitizedQuantConfig = sanitizeQuantConfig(quantConfig, segment);

    const runParams = {
      name,
      folder_id: folderId,
      quant_config: JSON.stringify(sanitizedQuantConfig),
      engine_version: propsEngineVersion,
      run_type: runType,
      order_type: orderType,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
      is_webhook_enabled: false,
      strategy_id: strategyId,
      granularity
    };

    const onHandleDefaultFolderAndExecuteRun = () => {
      getFolders().then((folders) => {
        const { id } = _.find(folders, 'is_default');

        executeRun({ ...runParams, folder_id: id });
      });
    };

    if (isUserLoggedIn) {
      if (!folderId) return onHandleDefaultFolderAndExecuteRun();
      return executeRun(runParams);
    }

    initiateUserAuth().then(() => {
      setIsUserLoggedIn(true);
      onHandleDefaultFolderAndExecuteRun();
    }).catch(() => {
      reactToastify('Unauthorized, Please Login and continue', TOASTIFY_TYPES.ERROR);
    });

    return null;
  };

  const renderUserDisbaledMessage = () => {
    if (isUserEnabled || !isUserLoggedIn) return null;

    return (
      <div className="alert alert-danger">
        <strong>User Disabled!&nbsp;</strong>
        You cannot run backtests or do live deployments.
        Please contact the administrator.
      </div>
    );
  };

  const renderFinnityWarningMessage = () => {
    const instruments = _.flattenDeep(instrumentGroups);
    const isIncludedFinnifty = _.includes(instruments, 'NFO:FINNIFTY');
    if (!isIncludedFinnifty) return null;

    return (
      <div className="alert alert-warning">
        There is bid ask difference in
        <strong className="ml-1">FINNIFTY</strong>
        . Beaware of market order
      </div>
    );
  };

  return (
    <div className="run-page-wrapper">
      <LoadingSpinner isLoading={isLoading}>
        <div className={`${isLoading ? 'is-loading' : ''}`}>
          <div className="run-form mb-5">
            {renderUserDisbaledMessage()}
            {renderFinnityWarningMessage()}
            <RunFormHeader isEditQuant={isEditQuant} />
            <RunFormHeadPiece
              isHistoricRunDisabled={isHistoricRunDisabled || isEditQuant}
              isLiveRunDisabled={isLiveRunDisabled}
              runType={runType}
              granularity={granularity}
              orderType={orderType}
              onHandleOrderType={setOrderType}
              onHandleRunType={setRunType}
              onHandleGranularity={setGranularity}
              planActiveTill={planActiveTill}
              onHandleEndDate={setEndDate}
              onHandleStartDate={setStartDate}
              name={name}
              onHandleName={setName}
              isUserLoggedIn={isUserLoggedIn}
              selectedFolder={selectedFolder}
              onHandleSelectedFolder={setSelectedFolder}
              validatorErrors={validatorErrors}
              calendarStartDate={calendarStartDate}
              calendarEndDate={calendarEndDate}
              startDate={startDate}
              endDate={endDate}
              currentUser={currentUser}
              instrumentGroups={instrumentGroups}
            />
            {isActualOrder && (<RunFormInstructions orderType={orderType} />)}
            <FormProvider {...methods}>
              <AdvancedBuilder
                orderType={orderType}
                isClonedQuant={isClonedQuant}
                runType={runType}
                onQuantConfigUpdate={handleQuantConfigUpdate}
                outputSchema={outputSchema}
                prebuiltId={prebuiltId}
                quantConfig={quantConfig}
                quantConfigValidators={quantConfigValidators}
                startDate={startDate}
                endDate={endDate}
                validatorErrors={validatorErrors}
                segment={segment}
                segments={segments}
                onChangeSegment={(newSegment) => setSegment(newSegment)}
              />
            </FormProvider>

            <div className="d-flex align-items-center flex-wrap">
              <div className="create-run-container">
                <CreditDetails
                  runType={runType}
                  instrumentGroups={instrumentGroups}
                  cases={_.get(quantConfig, 'cases', [])}
                />
                <button
                  onClick={methods.handleSubmit(handleCreateRun)}
                  type="submit"
                  className="btn btn-primary btn-lg track"
                  data-track-category="Create Run In Quant"
                  data-track-action="create"
                  data-track-label="Quant Form track"
                >
                  {I18n.t(getBtnTitle(), I18N_SCOPE)}
                </button>
              </div>
            </div>
          </div>
        </div>
      </LoadingSpinner>
      {isShowAutomaticConfirmationModal
        && (
          <RunFormConfirmationModal
            onClose={() => setIsShowAutomaticConfirmationModal(false)}
            onSubmit={methods.handleSubmit(() => handleCreateRun())}
            instrumentGroups={instrumentGroups}
          />
        )}
      <RunConfig quantConfig={quantConfig} />
    </div>
  );
};

Version1.propTypes = propTypes;
Version1.defaultProps = defaultProps;

export default withErrorBoundary(Version1);
