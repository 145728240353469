import React from 'react';
import PropTypes from 'prop-types';
import { userPropTypes, planPropTypes } from 'common/propTypes';
import { cancelSubscription } from 'common/api/subscriptions';
// import { updateUser } from 'common/api/userUpdate';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import { renderDateInPrettyFormat, getActualPlanEnddate } from 'common/utils/dateUtils';
import ThemeSettings from './ThemeSettings';
import UserDetails from './UserDetails/index';

const propTypes = {
  activeSubscriptionId: PropTypes.number,
  currentUser: userPropTypes.isRequired,
  plan: planPropTypes
};
const defaultProps = {
  plan: null,
  activeSubscriptionId: null
};

class MyProfile extends React.Component {
  cancelActiveSubscription = () => {
    const { activeSubscriptionId } = this.props;
    cancelSubscription(activeSubscriptionId)
      .then(() => window.location.reload())
      .catch(() => {
        reactToastify('No active subscriptions or something went wrong', TOASTIFY_TYPES.ERROR);
      });
  }

  renderCancelSubscriptionBtn() {
    const { activeSubscriptionId } = this.props;
    if (!activeSubscriptionId) {
      return null;
    }

    return (
      <div
        className="ml-5 btn btn-sm btn-outline-danger"
        onClick={this.cancelActiveSubscription}
      >
        Cancel Recurring Payment Subscription
      </div>
    );
  }

  renderPlanDetails = (detail, idx) => {
    const { label, value } = detail;
    return (
      <div className="info-view" key={idx}>
        <div className="info-view-label">{label}</div>
        <div className="info-view-value">{value}</div>
      </div>
    );
  };

  render() {
    const {
      activeSubscriptionId, currentUser, plan
    } = this.props;

    const planDetails = [
      { label: 'Plan', value: _.get(plan, 'plan_name', 'N/A') },
      {
        label: 'Active Till',
        value: renderDateInPrettyFormat(
          getActualPlanEnddate(currentUser.plan_active_till)
        )
      },
      {
        label: 'Recurring Payment',
        value: (
          <>
            {activeSubscriptionId > 0 ? 'Yes' : 'No'}
            {this.renderCancelSubscriptionBtn()}
          </>)
      },
      {
        label: 'Plan Backtest Credits',
        value: `Used: ${currentUser.quota_consumed_historic_runs} 
        | Max: ${currentUser.quota_max_historic_runs}`
      },
      {
        label: 'Plan Live Deployments',
        value: `Used: ${currentUser.quota_consumed_live_runs} 
        | Max: ${currentUser.quota_max_live_runs}`
      },
      {
        label: 'Addon Backtest Credits',
        value: `Used: ${currentUser.addon_consumed_historic_runs} 
        | Max: ${currentUser.addon_max_historic_runs}`
      },
      {
        label: 'Free Backtest Credits',
        value: `Used: ${currentUser.free_consumed_historic_runs}
        | Max: ${currentUser.free_max_historic_runs}`
      },
      {
        label: 'Quota',
        value: (
          <>
            <div className="badge badge-secondary">
              {renderDateInPrettyFormat(currentUser.quota_cycle_start_date)}
            </div>
            <div>&nbsp;to&nbsp;</div>
            <div className="badge badge-secondary">
              {renderDateInPrettyFormat(
                getActualPlanEnddate(currentUser.quota_cycle_end_date)
              )}
            </div>
          </>
        )
      }
    ];

    return (
      <>
        <UserDetails currentUser={currentUser} />
        <div className="card mt-3">
          <div className="card-header">
            <h6 className="tx-16">Plan/Quota/Addon</h6>
          </div>
          <div className="card-body">
            <div className="plan-Quota-wrapper">
              {_.map(planDetails, this.renderPlanDetails)}
            </div>
          </div>
        </div>
        <h4 className="mt-5 mb-3">Theme Settings</h4>
        <ThemeSettings />
      </>
    );
  }
}

MyProfile.propTypes = propTypes;
MyProfile.defaultProps = defaultProps;
export default MyProfile;
